<template>
  <div>
    <page-component
      pageTitle="Customers"
      :headers="headers"
      :items="customerData"
      :loadingData="loading"
      :buttons="[
        /*
        { text: 'Download Data', to: '' },
        { text: 'Send a Promotion', to: '' },
      */
      ]"
    >
      <template v-slot:filters[0]>
        <v-checkbox
          label="Show customer coupons"
          hide-details
          on-icon="mdi-checkbox-marked"
          off-icon="mdi-numeric-9-box-outline"
          dense
          @change="enableShowCoupons"
        />
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span class="text-no-wrap">{{ formatPhoneNumber(item.phone) }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span class="text-no-wrap" :title="item.createdAt">
          {{
            !item.createdAt
              ? ""
              : moment(item.createdAt).isValid()
              ? moment(item.createdAt).fromNow()
              : item.createdAt
          }}
        </span>
      </template>
      <template v-slot:[`item.lastOrderDate`]="{ item }">
        <span class="text-no-wrap">
          {{
            moment(item.lastOrderDate).isValid()
              ? moment(item.lastOrderDate).fromNow()
              : ""
          }}
        </span>
      </template>
      <template v-slot:[`item.totalPaid`]="{ item }">
        {{ item.totalPaid | currency }}
      </template>
      <template v-slot:[`item.totalDiscount`]="{ item }">
        {{ item.totalDiscount | currency }}
      </template>
      <template v-slot:[`item.avgTicketSize`]="{ item }">
        {{ item.avgTicketSize | currency }}
      </template>
      <template v-slot:[`item.numberOfOrders`]="{ item }">
        <v-menu
          v-if="item.orders && item.orders.length"
          class="mr-2"
          open-on-click
          offset-x
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined icon small v-bind="attrs" v-on="on">
              {{ item.numberOfOrders }}
            </v-btn>
          </template>
          <customer-orders
            :orders="item.orders"
            :customer="item.displayName"
            title-type="date-source"
            style="max-height: 500px"
          />
        </v-menu>
      </template>
      <template v-slot:[`item.availableCouponsCount`]="{ item }">
        <v-menu
          class="mr-2"
          open-on-click
          offset-x
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined icon small v-bind="attrs" v-on="on">
              {{ item.availableCouponsCount }}
            </v-btn>
          </template>
          <customer-coupons
            :coupons="item.availableCoupons"
            :customer="item.displayName"
            type="Available"
            style="max-height: 500px"
          />
        </v-menu>
      </template>
      <template v-slot:[`item.redeemedCouponsCount`]="{ item }">
        <v-menu
          class="mr-2"
          open-on-click
          offset-x
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined icon small v-bind="attrs" v-on="on">
              {{ item.redeemedCouponsCount }}
            </v-btn>
          </template>
          <customer-coupons
            :coupons="item.redeemedCoupons"
            :customer="item.displayName"
            type="Redeemed"
            style="max-height: 500px"
          />
        </v-menu>
      </template>
    </page-component>
  </div>
</template>

<script>
import CustomerOrders from "./CustomerOrders.vue"
import CustomerCoupons from "./CustomerCoupons.vue"
import { mapActions, mapState } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "customers",
  components: { CustomerOrders, CustomerCoupons, PageComponent },
  data() {
    return {
      loading: false,
      customerData: [],
      showCoupons: false,
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "customers", "customerOrders", "coupons"]),
    headers() {
      const headers = [
        { text: "ID", value: "uid" },
        { text: "Name", value: "displayName" },
        { text: "Signed Up", value: "createdAt" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: "Last Order", value: "lastOrderDate" },
        {
          text: "Average Ticket Size",
          value: "avgTicketSize",
        },
        {
          text: "Total Paid",
          value: "totalPaid",
        },
        {
          text: "Total Discount",
          value: "totalDiscount",
        },
        {
          text: "Orders",
          value: "numberOfOrders",
        },
      ]
      if (this.showCoupons) {
        headers.push(
          { text: "Available Coupons", value: "availableCouponsCount" },
          { text: "Redeemed Coupons", value: "redeemedCouponsCount" }
        )
      }
      return headers
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchCustomerOrders()
    this.customerData = this.customers
      .filter(
        c =>
          !(c.email.startsWith("kiosk") && c.email.endsWith("@nextgenkitchens.com"))
      )
      .map(c => {
        const r = { ...c }
        r.orders = this.customerOrders.filter(o => o.userId === r.uid)
        if (r.orders?.length) {
          r.totalDiscount = [...r.orders]
            .map(o => o.priceData?.discount || 0)
            .reduce((a, b) => {
              return a + parseFloat(b) / 100
            }, 0)
          r.totalPaid = [...r.orders]
            .map(o => o.priceData?.total || 0)
            .reduce((a, b) => {
              return a + parseFloat(b) / 100
            }, 0)
          r.avgTicketSize = r.totalPaid / r.orders.length
        } else {
          r.avgTicketSize = ""
        }
        r.numberOfOrders = r.orders.length
        r.lastOrderDate = this.getLastOrderDate(r)
        r.createdAt = r.createdAt?.toDate()
        return r
      })
    this.loading = false
  },
  methods: {
    ...mapActions(["fetchCustomerOrders", "fetchCustomerCoupons"]),
    getLastOrderDate(user) {
      if (!user?.orders?.length) {
        return ""
      }
      const orderDates = user.orders
        .map(order => order.orderDate.toDate())
        .sort((a, b) => (a < b ? 1 : -1))
      return orderDates[0]
    },
    formatPhoneNumber(phone) {
      return phone
        .replace(/[^\d]+/g, "")
        .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "($2) $3-$4")
    },
    async enableShowCoupons() {
      if (this.showCoupons) return false
      this.loading = true
      await this.fetchCustomerCoupons()
      this.customerData = this.customers
        .filter(
          c =>
            !(
              c.email.startsWith("kiosk") && c.email.endsWith("@nextgenkitchens.com")
            )
        )
        .map(c => {
          const r = { ...c }
          r.orders = this.customerOrders.filter(o => o.userId === r.uid)
          r.redeemedCoupons = r.redeemedCoupons
            .map(redeemedCoupon => {
              let thisCoupon = this.coupons.find(
                coupon => coupon.id === redeemedCoupon.couponId
              )
              thisCoupon = { ...redeemedCoupon, ...thisCoupon }
              return (
                thisCoupon || {
                  name: "Unknown",
                  id: redeemedCoupon.couponId,
                }
              )
            })
            .sort((a, b) => {
              return !a.redeemed
                ? -2
                : a.redeemed.toDate() < b.redeemed.toDate()
                ? 1
                : -1
            })
          r.redeemedCouponsCount = r.redeemedCoupons.length
          r.availableCoupons = r.availableCoupons
            // .filter(
            //   availableCoupon =>
            //     !r.redeemedCoupons?.find(redeeemedCoupon => {
            //       return redeeemedCoupon.couponId === availableCoupon.id
            //     })
            // )
            .map(availableCoupon => {
              let thisCoupon = this.coupons.find(
                coupon => coupon.id === availableCoupon.id
              )
              thisCoupon = { ...availableCoupon, ...thisCoupon }
              return (
                thisCoupon || {
                  name: "Unknown",
                  id: availableCoupon.id,
                }
              )
            })
            .filter(
              availableCoupon =>
                !r.redeemedCoupons?.find(redeeemedCoupon => {
                  return redeeemedCoupon.couponId === availableCoupon.id
                })
              // availableCoupon.referrerUserId === "hkGtkydtajVlGbFB2zUAstzQx473"
            )
          r.availableCouponsCount = r.availableCoupons.length
          if (r.orders?.length) {
            r.totalDiscount = [...r.orders]
              .map(o => o.priceData?.discount || 0)
              .reduce((a, b) => {
                return a + parseFloat(b) / 100
              }, 0)
            r.totalPaid = [...r.orders]
              .map(o => o.priceData?.total || 0)
              .reduce((a, b) => {
                return a + parseFloat(b) / 100
              }, 0)
            r.avgTicketSize = r.totalPaid / r.orders.length
          } else {
            r.avgTicketSize = ""
          }
          r.numberOfOrders = r.orders.length
          r.lastOrderDate = this.getLastOrderDate(r)
          return r
        })
      this.loading = false
      this.showCoupons = true
    },
  },
}
</script>

<style></style>
