<template>
  <v-card style="width: 400px" class="mb-n2">
    <v-card-title>{{ customer }}</v-card-title>
    <v-card-subtitle>Order History</v-card-subtitle>
    <v-expansion-panels class="my-2 light-background" style="width: 400px" multiple>
      <order-expandable-panel
        v-for="(order, i) in orders"
        :key="i"
        :title-type="titleType"
        :order="order"
      />
    </v-expansion-panels>
  </v-card>
</template>
<script>
import OrderExpandablePanel from "@/AuthenticatedContent/shared/OrderExpandablePanel"
export default {
  name: "customer-orders",
  components: {
    OrderExpandablePanel,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    customer: {
      type: String,
      required: true,
    },
    titleType: {
      type: String,
      default: "item-count-brands",
    },
  },
}
</script>
<style scoped>
.v-list {
  height: 300px !important;
  overflow-y: auto !important;
  max-width: 400px !important;
}
</style>
