<template>
  <v-card style="width: 400px" class="mb-n2">
    <v-card-title>{{ customer }}</v-card-title>
    <v-card-subtitle>
      {{ type ? type + " " : "" }}Coupons ({{ coupons.length }})
    </v-card-subtitle>
    <v-expansion-panels class="my-2" style="width: 400px" multiple>
      <v-expansion-panel v-for="(coupon, i) in coupons" :key="i">
        <v-expansion-panel-header>
          <div v-if="coupon.referralUserId">
            For referring {{ getUserDetails(coupon.referralUserId, "name") }}
          </div>
          <div v-else-if="coupon.referrerUserId">
            Referred by {{ getUserDetails(coupon.referrerUserId, "name") }}
          </div>
          <div v-else>
            {{ coupon.couponCode || coupon.name }}
          </div>
          <div v-if="coupon.redeemed">
            <small class="text--lighttext">
              {{ getRedeemedDate(coupon.redeemed) }}
            </small>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h2>Details:</h2>
          <p class="mt-2">
            Name: {{ coupon.name }}
            <br />
            Type:
            {{
              coupon.referrerUserId
                ? "Got referred"
                : coupon.referralUserId
                ? "Referred someone"
                : coupon.type
            }}
            <br />
            Code: {{ coupon.couponCode }}
            <br />
            <span v-if="coupon.rate">
              Rate:
              <span v-if="coupon.rate.type === 'fixed'">$</span>
              {{ coupon.rate.amount }}
              <span v-if="coupon.rate.type !== 'fixed'">%</span>
              <br />
            </span>
            ID: {{ coupon.id || coupon.couponId }}
          </p>
          <div v-if="coupon.referralUserId">
            <h2>Referred User:</h2>
            <p class="mt-2">
              Name: {{ getUserDetails(coupon.referralUserId, "name") }}
              <br />
              ID: {{ getUserDetails(coupon.referralUserId, "id") }}
              <br />
              Coupons Redeemed:
              {{ getUserDetails(coupon.referralUserId, "coupons") }}
            </p>
          </div>
          <div v-if="coupon.referrerUserId">
            <h2>Referred By:</h2>
            <p class="mt-2">
              Name: {{ getUserDetails(coupon.referrerUserId, "name") }}
              <br />
              ID: {{ getUserDetails(coupon.referrerUserId, "id") }}
              <br />
              Coupons redeemed:
              {{ getUserDetails(coupon.referrerUserId, "coupons") }}
            </p>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
export default {
  name: "customer-coupons",
  props: {
    coupons: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "",
    },
    customer: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["customers"]),
  },
  methods: {
    getUserDetails(uid, detail) {
      const user = this.customers.find(u => u.uid === uid)
      switch (detail) {
        case "name":
          return user.displayName
        case "id":
          return user.uid
        case "coupons":
          return user.redeemedCoupons.length + " / " + user.availableCoupons.length
        default:
          return user
      }
    },
    getRedeemedDate(date) {
      return moment(date.toDate()).format("MMM D h:mm a")
    },
  },
}
</script>
<style scoped>
.v-list {
  height: 300px !important;
  overflow-y: auto !important;
  max-width: 400px !important;
}
</style>
